@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900&display=swap&subset=cyrillic');

* {
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d2d2f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #595962;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #595962;
}

body {
  background: #1d1d1f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #fff;
  word-break: break-word;
  font-size: 11px;
  line-height: 1.2;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.Modal {
  &.seed {
    min-width: 664px;
    min-height: 280px;
    .modalBody {
      overflow-y: hidden;
    }
  }
  &.drainer-info {
    min-height: 280px;
    min-width: 550px;
    .modalBody {
      overflow-y: hidden;
    }
    .modalBody .infoRow .infoBody {
      display: flex;
      align-items: center;
    }
  }
  &.transactions {
    min-height: 280px;
    min-width: 800px;
    .modalBody {
      padding-top: 10px !important;
      padding-left: 20px !important;
      padding-bottom: 25px !important;
      overflow-y: auto;
    }
  }
  &.nftblacklist {
    min-width: 500px;
    .modalBody {
      overflow: hidden;
      padding-top: 10px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
  z-index: 999999;
  background-color: #252527;
  backdrop-filter: blur(10px);
  min-width: 600px;
  outline: none;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.Overlay {
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 29, 31, 0.8);
  backdrop-filter: blur(10px);
}

.modalHeader {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(81, 81, 85, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  .content {
    display: flex;

    .tab {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 700;
      color: #5b5b5b;
      text-transform: uppercase;
      justify-content: center;
      min-width: 65px;
      cursor: pointer;
      padding: 0px 10px;
      height: 60px;

      &.note {
        padding: 0px 25px;
        .circle {
          background-color: #f15858;
          width: 8px;
          height: 8px;
          border-radius: 50px;
          margin-left: 10px;
        }
      }

      &.active {
        color: #fff;
        border-bottom: 1px solid #4ba9ff;
      }

      .badge {
        box-sizing: border-box;
        color: #4ba9ff;
        background-color: rgba(72, 161, 242, 0.18);
        padding: 5px 10px;
        border-radius: 4px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .close {
    cursor: pointer;

    svg {
      fill: #48484b;
    }
  }
}

.modalBody {
  width: 100%;
  border-bottom: 1px;
  box-sizing: border-box;
  padding: 30px 10px;
  border-color: rgba(81, 81, 85, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: calc(85vh);
  padding-bottom: 50px;
  overflow-y: scroll;

  .note-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .note-body {
      width: 90%;
      position: relative;

      svg {
        position: absolute;
        width: 17px;
        height: 20px;
        bottom: 15px;
        right: 15px;
        cursor: pointer;
      }

      textarea.note {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 20px;
        font-family: 'Montserrat', sans-serif;
        resize: none;
        background-color: #202023;
        color: #f5f5f5;
        border-radius: 9px;
        border: none;
        font-size: 12px;
        font-weight: 600;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }

  .updateDate {
    position: absolute;
    bottom: 20px;
    right: 30px;
    color: #868686;
    font-size: 13px;
    font-weight: 400;
  }

  .transactions-table {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    position: relative;
    .head {
      position: absolute;
      top: 0;
      left: 5%;
      width: 90%;
      display: flex;
      .cell {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.55);
      }
    }
    .rows {
      margin-top: 25px !important;
      max-height: 400px;
      overflow-y: scroll;
    }
    .row {
      display: flex;
      width: 100%;
      height: 200px;
      align-items: center;
      justify-content: space-between;
      background-color: #222225;
      border-radius: 12px;
      margin-top: 8px;
      height: 40px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      .cell {
        background-color: #1f1f22;
        height: 25px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 9px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        color: #e2e2e2;
        padding-left: 10px;

        &.blue {
          background-color: rgba(72, 161, 242, 0.06);
          justify-content: center;
          padding-left: 0px;
          text-decoration: none;
        }
      }
    }
  }

  .blacklist-modal-item {
    width: 100%;
    height: 30px;
    margin-top: 10px;

    display: flex;

    .address {
      box-sizing: border-box;
      color: #4ba9ff;
      background-color: rgba(72, 161, 242, 0.1);
      font-size: 12px;
      font-weight: 400;
      padding: 5px 10px;
      border-radius: 4px;
      margin-right: 10px;
      width: calc(100% - 30px);
      display: flex;
      cursor: pointer;
      text-decoration: none;
      justify-content: center;
      align-items: center;
    }

    .remove {
      box-sizing: border-box;
      background-color: rgba(255, 70, 70, 0.27);
      color: #ff7a7d;
      padding: 7px;
      border-radius: 4px;
      width: 30px;
      cursor: pointer;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #ff4b4b;
      }
    }
  }

  .blacklist-modal-input {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    input {
      font-family: 'Montserrat', sans-serif;
      border: none;
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
      background-color: #18181b;
      color: #404040;
      outline: none;
      height: 40px;
      width: calc(100% - 40px);
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      transition: all 0.2s ease-in-out;
      font-weight: 700;
      &:focus {
        color: #9a9a9a;
        text-align: left;
      }
    }
    button {
      cursor: pointer;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      height: 40px;
      width: 40px;
      background-color: #18181b;
      border: none;
    }
  }

  .infoRow {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 400;
    color: #ccc;
    line-height: 25px;
    max-width: 640px;
    padding: 0px 15px;
    box-sizing: border-box;
    .infoTitle {
      width: 15%;
      font-size: 13px;
      max-width: 500px;
      color: #fff;
      font-weight: 700;
      display: flex;
    }
    .infoBody {
      max-width: 85%;

      .badge {
        box-sizing: border-box;
        text-decoration: none;
        color: #4ba9ff;
        background-color: rgba(72, 161, 242, 0.1);
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        border-radius: 4px;
        margin-left: 10px;
      }

      .seedBadge {
        box-sizing: border-box;
        text-decoration: none;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.1);
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        padding-right: 30px;
        position: relative;
        border-radius: 4px;
        margin-left: 10px;
        width: 100%;

        svg {
          fill: #4a4a4a;
          width: 16px;
          height: 16px;
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }

  .balanceRow {
    &:first-child {
      margin-top: 0;
    }

    height: 40px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 25px;

    .badge {
      &.error {
        background-color: rgba(255, 70, 70, 0.27);
        color: #ff7a7d;
      }
      &:first-child {
        margin-left: 0;
      }

      &:nth-child(1) {
        width: 130px;
      }

      &:nth-child(2) {
        width: 270px;
      }
      box-sizing: border-box;
      text-decoration: none;
      color: #4ba9ff;
      background-color: rgba(72, 161, 242, 0.1);
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px;
      border-radius: 4px;
      margin-left: 10px;
    }
  }
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url(../assets/img/login-fill.svg);
  background-size: cover;
  background-repeat: no-repeat;

  .logo-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    svg {
      width: 420px;
      height: 85px;
    }

    .logo-wrap {
      background-image: url(../assets/img/login-bg.png);
      width: 635px;
      height: 345px;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .login-block-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    position: relative;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(69, 69, 72, 0.06);
      -webkit-backdrop-filter: blur(10px);
      -o-backdrop-filter: blur(10px);
      -moz-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      z-index: 0;
    }

    .form {
      z-index: 999;
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .error {
        &.show {
          opacity: 1;
          visibility: visible;
        }
        font-size: 13px;
        color: #f62d2d;
        margin-top: 15px;
        opacity: 0;
        visibility: hidden;
        transition: all 200ms ease-in-out;
      }

      input {
        width: 330px;
        height: 50px;
        margin-bottom: 18px;
        background-color: #18181b;
        border: none;
        color: #404040;
        font-family: 'Montserrat', sans-serif;
        border-radius: 7px;
        font-weight: 700;
        font-size: 13px;
        vertical-align: middle;
        padding-left: 25px;
        box-sizing: border-box;
        outline: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #404040;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 10px;
        }
      }

      button {
        width: 330px;
        height: 50px;
        background-color: #4ba9ff;
        color: #ffffff;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-weight: 600;
        font-size: 12px;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.container {
  width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.green {
  color: #a4d85b !important;
}

.grey {
  color: #6f6f6f !important;
}

.orange {
  color: #f6512d !important;
}

.blue {
  color: #4bb5ff !important;
}

.yellow {
  color: #f6b22e !important;
}

.header {
  display: flex;
  width: 100%;
  height: 115px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.03);
  align-items: center;
  justify-content: space-between;
}

.navigation {
  display: flex;
  align-items: center;
}

.logo {
  margin-bottom: 10px;
}

.menu {
  display: flex;
  margin-left: 50px;
  position: relative;

  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    text-decoration: none;

    &:not(:first-child) {
      margin-left: 40px;
    }

    svg {
      fill: #5b5b5b;
      transition: fill 0.4s ease;
    }

    &.active svg {
      fill: #4ba9ff;
    }

    &::after {
      content: '';
      width: 32px;
      height: 2px;
      background-color: rgba(250, 250, 250, 0.15);
      position: absolute;
      bottom: -34px;
      left: 40%;
      transition: opacity 0.4s ease;
      opacity: 0;
    }

    &.active::after {
      opacity: 1;
    }

    span {
      color: #5b5b5b;
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      transition: color 0.4s ease;
      margin-left: 10px;
    }
  }

  .selline {
    width: 32px;
    height: 2px;
    background-color: rgba(250, 250, 250, 0.15);
    position: absolute;
    bottom: -34px;
    left: 40%;
  }

  .item.active span {
    color: #fff;
  }
}

.profile {
  display: flex;
  height: 40px;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-right: 18px;
  }
}

.main-content-block {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.block-head {
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 40px;
  box-sizing: border-box;
  margin-bottom: 32px;

  .name {
    color: #ccc;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link {
    color: #5b5b5b;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .sortWrapper {
    display: flex;
    flex-direction: row;

    .sort {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #5b5b5b;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 11px;
      margin-left: 10px;
      margin-right: 10px;
      position: relative;

      span {
        color: #cccccc;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }

      .sortList {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 50px;
        background-color: #2b2b2d;
        border-radius: 7px;
        width: 100%;
        z-index: 9999;
        transition: visibility 0.2s, opacity 0.2s ease;
        overflow: hidden;
      }

      &.opened .sortList {
        opacity: 1;
        visibility: visible;
      }

      .sortList span {
        box-sizing: border-box;
        padding-left: 15px;
        height: 45px;
        color: #6b6b74;
        background-color: #2b2b2d;
        font-size: 11px;
        font-weight: 700;
        transition: background, color 0.2s ease;
        margin-left: 0;
        border-bottom: 1px solid #333335;

        &:last-child {
          border: none;
        }

        &:hover {
          color: #e0e0ec;
          background-color: #2f2f31;
        }
      }

      &.opened span svg {
        transform: rotate(180deg);
      }

      span svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        transition: transform 0.1s ease;
      }
    }
  }
}

.searchField {
  display: flex;

  .searchButton {
    background-color: #18181b;
    box-sizing: border-box;
    height: 40px;
    width: 50px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    align-items: center;
  }

  input {
    width: 200px;
    height: 40px;
    background-color: #18181b;
    border: none;
    color: #404040;
    font-family: 'Montserrat', sans-serif;
    border-radius: 7px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-weight: 700;
    font-size: 13px;
    vertical-align: middle;
    padding-left: 25px;
    box-sizing: border-box;
    outline: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #404040;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 13px;
    }
  }
}

.main-content-block {
  &.settings {
    flex-direction: column;
  }
  .fullwidth {
    width: 100%;
    display: flex;
    flex-direction: column;

    .settings-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      background-color: #212123;
      min-height: 100px;
      justify-content: center;
      box-sizing: border-box;
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 50px;

      .input-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        p {
          color: #bbbbbb;
          font-size: 13px;
          font-weight: 700;
        }
        input {
          font-family: 'Montserrat', sans-serif;
          background-color: #18181b;
          color: #404040;
          outline: none;
          border-radius: 7px;
          border: none;
          height: 40px;
          width: 250px;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          text-align: center;
          text-transform: uppercase;
          font-size: 10px;
          transition: all 0.2s ease-in-out;
          font-weight: 700;

          &:focus {
            color: #9a9a9a;
            text-align: left;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(190, 190, 190, 0.05);
        }
        .settings-modal-opener {
          height: 40px;
          width: 250px;
          background-color: #2d2d30;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 7px;
          cursor: pointer;
          p {
            text-transform: uppercase;
            font-size: 11px;
            color: #7b7b7b;
          }
          img {
            width: 12px;
            height: 12px;
          }
        }
        button {
          height: 40px;
          display: flex;
          justify-content: center;
          user-select: none;
          align-items: center;
          border-radius: 5px;
          font-weight: 600;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 12px;
          border: none;
          cursor: pointer;
          min-width: 120px;
          transition: opacity 0.2s ease-in-out;
          margin-right: 25px;
          &.blue-btn {
            background-color: #4ba9ff;
            color: #ffffff;
          }
          &:hover {
            opacity: 0.9;
          }
          &:disabled {
            cursor: auto;
            background-color: #2e2e30;
            color: #cccccc;
          }
        }
        .buttons-group {
          display: flex;
          flex-direction: row;
        }
        .custom-checkbox {
          display: block;
          position: relative;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &.checked .checkmark {
            background-color: #69af50;
            .dot {
              background-color: #ffffff;
              transform: translateX(120%);
            }
          }
          & .checkmark {
            box-sizing: border-box;
            padding: 4px 3px;
            width: 50px;
            background-color: #2d2d30;
            border-radius: 7px;
            display: flex;
            position: relative;
            box-sizing: border-box;
            .dot {
              display: block;
              width: 20px;
              height: 20px;
              background-color: #676771;
              border-radius: 4px;
              transition: transform 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }

  .left {
    width: 885px;
    display: flex;
    flex-direction: column;

    .chart-wrap {
      border-radius: 12px;
      background-color: #212123;
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 50px;
      padding-bottom: 15px;
      box-sizing: border-box;
      height: 350px;
      position: relative;
    }
  }

  .map-block {
    width: 100%;
    display: flex;
    flex-direction: column;

    .map-wrap {
      border-radius: 12px;
      background-color: #212123;
      box-sizing: border-box;
      min-height: 350px;
      height: calc(100vh - 750px);
      margin-bottom: 50px;
      position: relative;
    }
  }
}

.maplibregl-map {
  border-radius: 12px;
}

.mapMarker {
  width: 24px;
  height: 24px;
  background-color: #4ba9ff;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content-block .right {
  margin-left: 15px;
  width: 500px;
  display: flex;
  flex-direction: column;
}

.new-wallets {
  display: flex;
  flex-direction: column;

  .wallet {
    width: 514px;
    height: 80px;
    background-color: #212123;
    border-radius: 12px;
    margin-bottom: 10px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-left: 17px;
    padding-right: 40px;
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
      background-color: #28282c;

      .left .icon {
        fill: #717178;
      }

      .date {
        color: #7e7e85;
      }

      .eth {
        color: #dedee5;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 44px;
        height: 44px;
        fill: #323234;
        transition: fill 0.2s ease;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      margin-left: 18px;

      .date {
        font-size: 11px;
        color: #545457;
        font-weight: 600;
        transition: color 0.2s ease;
      }

      .eth {
        font-size: 12px;
        color: #969699;
        font-weight: 600;
        padding-top: 5px;
        transition: color 0.2s ease;
      }
    }

    .summ {
      font-weight: 700;
      font-size: 12px;
      inline-size: 200px;
      text-align: right;
    }
  }
}

.logsTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #212123;
  min-height: 800px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 50px;

  .loadMore {
    width: 100%;
    color: #757579;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #38383a;
    border-radius: 12px;
  }

  .head {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 40px;

    .cell {
      color: #5b5b5b;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .log.no-padding,
  .head.no-padding {
    padding-left: 30px;
  }

  .log {
    width: 100%;
    height: 65px;
    background-color: #252527;
    border-radius: 12px;
    display: flex;
    margin-top: 10px;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 40px;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: background 0.2s ease;

    .walletIcon {
      fill: #717178;
      width: 27px;
      height: 27px;
      position: absolute;
      left: 20px;
      top: 19px;
    }

    .noteIcon {
      width: 17px;
      height: 17px;
      position: absolute;
      left: 45px;
      top: 10px;
    }

    .ethLogo {
      fill: #28282b;
      position: absolute;
      z-index: 0;
      left: 45px;
      top: 0;
      transition: fill 0.2s ease;
    }

    .btcLogo {
      fill: #28282b;
      position: absolute;
      z-index: 0;
      left: 285px;
      top: 0;
      transition: fill 0.2s ease;
    }

    .freshIcon {
      fill: #28282a;
      position: absolute;
      z-index: 0;
      left: 0px;
      top: 0;
      transition: fill 0.2s ease;
    }

    .cell {
      color: #e5e4de;
      font-size: 12px;
      font-weight: 600;
      z-index: 999;
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        color: #e5e4de;
      }
    }

    &:hover {
      background-color: #2e2e31;

      .ethLogo,
      .btcLogo,
      .freshIcon {
        fill: #363639;
      }

      .cell .showButton {
        background-color: #4ba9ff;
      }
    }

    .cell {
      img {
        margin-left: 5px;
      }

      svg {
        fill: #4a4a4a;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        cursor: pointer;
      }

      .linkIcon {
        fill: none !important;
      }

      .balance {
        cursor: pointer;
      }

      .showButton {
        transition: background 0.2s ease;
        margin-left: 30px;
        width: 110px;
        height: 45px;
        border-radius: 5px;
        background-color: #2e2e30;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
